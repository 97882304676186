import React from 'react';

import Filtro from './Filtro';
import Lista from './Lista';
import HistoricoImportacao from './HistoricoImportacao';

function Resultado() {
  return (
    <>
      <Filtro />
      <HistoricoImportacao />
      <Lista />
    </>
  );
}

export default Resultado;

import React from 'react';

import { Empty } from 'antd';
import PropTypes from 'prop-types';

import S from './styles';

function List({ emptyText, height, onChange, onShowSizeChange, pagination, ...propsList }) {
  return (
    <S.List
      height={height}
      size="small"
      locale={{ emptyText: <Empty description={emptyText} /> }}
      pagination={{
        pageSizeOptions: ['10', '20', '50', '100'],
        showTotal: (count, range) => `${range[0]}-${range[1]} de ${count} itens`,
        onChange: (page, pageSize) => {
          return onChange(page, pageSize);
        },
        onShowSizeChange: (current, size) => {
          return onShowSizeChange(current, size);
        },
        showSizeChanger: true,
        hideOnSinglePage: true,
        ...pagination,
      }}
      {...propsList}
    />
  );
}

List.Item = S.List.Item;

List.defaultProps = {
  height: 'auto',
  emptyText: 'Nenhuma informação para mostrar',
  onChange: () => null,
  onShowSizeChange: () => null,
};

List.propTypes = {
  height: PropTypes.string,
  emptyText: PropTypes.string,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
};

export default List;

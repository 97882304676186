import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { UploadOutlined } from '@ant-design/icons';
import { faFileUpload, faMousePointer } from '@fortawesome/free-solid-svg-icons';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';

import { useImportacao } from '~/Context/importacao';
import { importacaoServico } from '~/Servicos';
import AppContext from '~/Views/App/AppContext';

import S from './styles';

const { upload } = importacaoServico;

function Importar({ children }) {
  const { loading } = useContext(AppContext);
  const { cnpj } = useImportacao();

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);

  // Caso troque o CNPJ, limpar a lista de upload
  useEffect(() => {
    if (fileList.length) {
      setFileList([]);
    }
  }, [cnpj]); // eslint-disable-line

  const doEnviarArquivo = () => {
    setUploading(true);
    upload(fileList, cnpj)
      .then((response) => {
        setFileList([]);
        message.success('Arquivo enviado com sucesso!');
      })
      .catch((error) => {
        message.error(
          'No momento estamos com problemas de comunicação com nossos servidores. Tente novamente mais tarde!'
        );
      })
      .finally(() => setUploading(false));
  };

  const draggerProps = {
    // accept: '.xml,.zip,.rar',
    multiple: true,
    showUploadList: true,
    disabled: !cnpj,
    onRemove: (file) => {
      const newFileList = [...fileList];
      const index = newFileList.indexOf(file);
      newFileList.splice(index, 1);
      setFileList([...newFileList]);
    },
    beforeUpload: (file, fileList) => {
      const tipos = [".xml", ".zip", ".rar"];
      const isOk = tipos.some(x => {
        if (file.name.includes(x)) {
          return true;
        } else {
          return false;
        }
      })

      if (!isOk) {
        message.error(`O arquivo importado ${file.name} está com formato inválido, favor compactar no formato .Zip ou .Rar`);
        return;
      }
      if (fileList.length <= 10) {
        setFileList((prev) => [...prev, file]);
      } else {
        message.error('Limite máximo de 10 arquivos por importação. Para enviar mais de 10, compacte seu arquivos em .zip ou .rar e tente novamente');
      }
      return false;
    },
    fileList,
  };

  return (
    <S.Upload>
      <S.Dragger {...draggerProps}>
        {loading ? (
          <Skeleton height={250} />
        ) : !cnpj ? (
          <>
            <p className="ant-upload-drag-icon">
              <S.Upload.Icon icon={faMousePointer} size="3x" />
            </p>
            <p className="ant-upload-text">
              Selecione uma empresa para importar arquivos ou visualizar os seus resultados
            </p>
          </>
        ) : (
          <>
            <p className="ant-upload-drag-icon">
              <S.Upload.Icon icon={faFileUpload} size="3x" />
            </p>
            <p className="ant-upload-text">Clique aqui ou arraste e solte nesta área para enviar</p>
            <p className="ant-upload-hint">Somente arquivos em formato .zip ou .rar</p>
          </>
        )}

        {children}
      </S.Dragger>

      {!!fileList.length && (
        <Button
          type="default"
          size="small"
          loading={uploading}
          icon={<UploadOutlined />}
          onClick={doEnviarArquivo}
        >
          Enviar
        </Button>
      )}
    </S.Upload>
  );
}

Importar.defaultProps = {
  children: null,
};

Importar.propTypes = {
  children: PropTypes.node,
};

export default Importar;

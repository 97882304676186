import axios from '~/Constants/axiosConfig';

const upload = async (fileList, cnpj) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  fileList.forEach((file) => formData.append('arquivos', file));
  formData.append('cnpj', cnpj);

  return axios.post(`Arquivo?cnpj=${cnpj}`, formData, config);
};

const getImportacao = async (params) => {
  return axios
    .get('importacao', { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const getNotificacaoImportacao = async (idImportacao) => {
  return axios
    .get(`importacao/${idImportacao}/notificacoes`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

async function exportarRelatorioNotificacao(idImportacao, cnpj) {
  try {
    const route = `importacao/${idImportacao}/exportar`

    const response = await axios.get(route,
      {
        responseType: "blob",
      });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Notificação${cnpj !== '' ? ' - ' + cnpj : ''}.xlsx`);
    document.body.appendChild(link);
    link.click();

    return {
      Ok: true,
      Message: "Relatório gerado com sucesso",
    };
  } catch (err) {
    return {
      Ok: false,
      Message: "Ocorreu um erro ao gerar o relatório",
    };
  }
}

export default { upload, getImportacao, getNotificacaoImportacao, exportarRelatorioNotificacao };

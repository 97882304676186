import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '~/Views/App/AppContext';

import S from './styles';

function Footer() {
  const { config, loading } = useContext(AppContext);

  const formataUrlRedeSocial = (redeSocial) => {
    if (!redeSocial?.url.startsWith('https://')) {
      switch (redeSocial.nome.toLowerCase()) {
        case 'facebook':
          return `https://www.facebook.com/${redeSocial.url}`;
        case 'twitter':
          return `https://twitter.com/${redeSocial.url}`;
        case 'linkedin':
          return `https://br.linkedin.com/${redeSocial.url}`;
        case 'youtube':
          return `https://www.youtube.com/${redeSocial.url}`;
        case 'instagram':
          return `https://www.instagram.com/${redeSocial.url}`;
        default:
          break;
      }
    }
    return redeSocial?.url;
  };

  return (
    <>
      {loading ? (
        <Skeleton height={50} />
      ) : (
        <S.Footer>
          <S.Text>
            <S.Logo src={config.logo} alt="Logo empresa" />
              &copy; {` ${config.rodape && config.rodape.nome !== undefined ? config.rodape.nome +' .' : ''} Todos os direitos reservados.`}
          </S.Text>

          <S.Social>
            {config.redesSociais.map((social) => (
              <S.SocialLink
                key={social.nome}
                href={formataUrlRedeSocial(social)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={social.icone} size="lg" />
              </S.SocialLink>
            ))}
          </S.Social>
        </S.Footer>
      )}
    </>
  );
}

export default Footer;

import { Table as AntTable } from 'antd';
import styled from 'styled-components';

const Table = styled(AntTable)`
  .ant-table-thead {
    & > tr {
      & > th,
      & > th:hover {
        background-color: ${({ theme }) => theme.colors.secundario || '#222b57'};
        color: ${({ theme }) => theme.colors.texto || '#fff'};
      }

      & > th:hover {
        opacity: 0.95;
      }
    }
  }
`;

export default { Table };

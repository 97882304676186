import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import S from './styles';

function FieldCustom({ children, icon, ...propsField }) {
  return (
    <S.Field {...propsField}>
      {icon && <FontAwesomeIcon icon={icon} size="lg" color="#bebebe" />}

      {children}
    </S.Field>
  );
}

FieldCustom.defaultProps = {
  children: null,
  icon: null,
};

FieldCustom.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.objectOf(PropTypes.any),
};

export default FieldCustom;

import AlterarSenha from '~/Views/Auth/AlterarSenha';
import Login from '~/Views/Auth/Login';
import EsqueciMinhaSenha from '~/Views/Auth/EsqueciMinhaSenha';
import NovaSenha from '~/Views/Auth/NovaSenha';
import Home from '~/Views/Home';

const routes = [
  {
    key: 'login',
    private: false,
    exact: true,
    layout: false,
    path: '/',
    component: Login,
  },
  {
    key: 'esqueci-minha-senha',
    private: false,
    exact: true,
    layout: false,
    path: '/esqueci-minha-senha',
    component: EsqueciMinhaSenha,
  },
  {
    key: 'nova-senha',
    private: false,
    exact: true,
    layout: false,
    path: '/nova-senha',
    component: NovaSenha,
  },
  {
    key: 'alterar-senha',
    private: true,
    exact: true,
    layout: false,
    path: '/alterar-senha',
    component: AlterarSenha,
  },
  {
    key: 'home',
    private: true,
    exact: true,
    layout: true,
    path: '/home',
    component: Home,
  },
];

export default routes;

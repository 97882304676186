import Layout from 'antd/lib/layout';
import styled from 'styled-components';

const Footer = styled(Layout.Footer)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #f7f7f7;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  height: 1rem;
  margin-right: 1rem;
`;

const Social = styled.div`
  display: flex;
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  color: #fff !important;
  background-color: #24bba7;
  border-radius: 100%;
  margin: 0 4px;

  &:hover {
    background-color: #1aa592;
    color: white !important;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.secondaryText};
`;

export default {
  Footer,
  Logo,
  Social,
  SocialLink,
  Text,
};

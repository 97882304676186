export function getStorage(name, tipo) {
  const novoTipo = String(tipo).toLowerCase();

  if (novoTipo === 'local') {
    return JSON.parse(localStorage.getItem(name));
  }
  if (novoTipo === 'session') {
    return JSON.parse(sessionStorage.getItem(name));
  }

  return null;
}

export function setStorage(name, data, tipo) {
  const novoTipo = String(tipo).toLowerCase();

  if (novoTipo === 'session') {
    sessionStorage.setItem(name, JSON.stringify(data));
  } else {
    localStorage.setItem(name, JSON.stringify(data));
  }
}

export function removeItemStorage(name, tipo) {
  const novoTipo = String(tipo).toLowerCase();

  if (novoTipo === 'local') {
    localStorage.removeItem(name);
  }
  if (novoTipo === 'session') {
    sessionStorage.removeItem(name);
  }
}

export function clearStorage(tipo) {
  const novoTipo = String(tipo).toLowerCase();

  if (novoTipo === 'local') {
    localStorage.clear();
  } else if (novoTipo === 'session') {
    sessionStorage.clear();
  } else {
    localStorage.clear();
    sessionStorage.clear();
  }
}

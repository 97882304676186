import memoize from 'fast-memoize';

import { encrypt, decrypt } from './crypto';
import { getStorage, setStorage, clearStorage } from './storage';

const USER_DATA = 'RevendaUserData';
const TOKEN_ENCRYPT_KEY = 'EA@ContaUnica!@#';
const memoizeCache = new Map();

/**
 * Armazena o objeto de Sessão no Storage para permitir o compartilhamento do mesmo entre as abas do browser.
 * Usar o sessionStore implica que a informação adicionada nele só é válida na aba do browser em que foi gerado.
 * Como mecanismo de proteção, criptografamos as informações de Sessão para dificultar que um usuário consiga ter
 * acesso ao mesmo. Entretanto qualquer regra de restrição aplicada no frontend deve ser validada no backend!!!
 */

function setObject(key, value, encryptKey) {
  try {
    memoizeCache.clear();
    return setStorage(
      key,
      encrypt(btoa(unescape(encodeURIComponent(JSON.stringify(value)))), encryptKey),
      "session"
    );
  } catch (e) {
    // console.log(`Erro ao setar o objeto no sessionStore: ${e}`);
    return undefined;
  }
}

const getObject = memoize(
  (key, encryptKey) => {
    try {
      const value = getStorage(key, "session");
      if (value) return JSON.parse(decodeURIComponent(escape(atob(decrypt(value, encryptKey)))));
      return value;
    } catch (e) {
      // console.log(`Erro ao buscar o objeto no sessionStore: ${e}`);
      return undefined;
    }
  },
  { cache: { create: () => memoizeCache } }
);

export const Sessao = (key) => {
  const token = getObject(USER_DATA, TOKEN_ENCRYPT_KEY);
  if (token) {
    return token[key];
  }
  return undefined;
};

export const setSessaoToken = (token) => setObject(USER_DATA, token, TOKEN_ENCRYPT_KEY);

export const limparSessao = () => {
  clearStorage();
};

/* Caso esteja em desenvolvimetno exporta a função Sessao, para facilitar a depuração */
if (process.env.NODE_ENV === 'development') {
  window.GetSessao = () => getObject(USER_DATA, TOKEN_ENCRYPT_KEY);
  window.SetSessao = (obj) => setObject(USER_DATA, obj, TOKEN_ENCRYPT_KEY);
  window.EACache = () => memoizeCache;
}

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input, message } from 'antd';
import sha1 from 'crypto-js/sha1';

import AppContext from '~/Views/App/AppContext';

import S from './styles';
import { novaSenha } from '~/Servicos/autenticacao';

function NovaSenha() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const search = useLocation().search;
  const codigo = new URLSearchParams(search).get('codigo');

  const {
    config: { logo, subdominio },
  } = useContext(AppContext);

  const onFinish = (values) => {
    const data = {
      codigo,
      senha: sha1(values.senhaNova.trim()).toString(),
    };

    novaSenha(data).then((response) => {
      if (response && response.sucesso) {
        setLoading(false);
        history.push(`/${subdominio}`);
      } else {
        setLoading(false);

        if (response && response.mensagem) {
          message.error(response.mensagem);
        } else {
          message.error('Erro ao solicitar nova senha');
        }
      }
    });
  };

  const validarSenha = (rule, value, dependencie) => {
    if (!value) {
      return Promise.reject('Por favor, confirme a Nova Senha');
    }

    if (value.length < 6) {
      return Promise.reject('A senha deve conter no mínimo 6 caracteres');
    }

    if (dependencie) {
      if (form.getFieldValue(dependencie) === value) {
        return Promise.resolve();
      }

      return Promise.reject('As duas senhas digitadas não coincidem');
    }

    return Promise.resolve();
  };

  return (
    <S.Container>
      <S.Box>
        <S.Brand src={logo} />
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="senhaNova"
            rules={[{ validator: (rule, value) => validarSenha(rule, value, null) }]}
            hasFeedback
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Nova Senha" />
          </Form.Item>

          <Form.Item
            name="senhaNovaConfirmar"
            dependencies={['senhaNova']}
            rules={[{ validator: (rule, value) => validarSenha(rule, value, 'senhaNova') }]}
            hasFeedback
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Confirmar Nova Senha"
            />
          </Form.Item>

          <Form.Item>
            <S.Button type="primary" htmlType="submit" size="large" loading={loading}>
              Confirmar
            </S.Button>
          </Form.Item>
        </Form>
      </S.Box>
    </S.Container>
  );
}

export default NovaSenha;

import React, { memo, useState } from 'react';

import { BellFilled } from '@ant-design/icons';
import { Button, Modal, Tag, Tooltip } from 'antd';
import moment from 'moment';

import List from '~/Components/List';
import { useImportacao } from '~/Context/importacao';
import { importacaoServico } from '~/Servicos';
import { Sessao } from '~/Helpers/token';

function HistoricoImportacao() {
  const { cnpj, listaImportacao, loading } = useImportacao();
  const [isFetching, setFetching] = useState(false);

  const doBuscarNotificacao = (importacao) => {
    setFetching(true);
    importacaoServico
      .getNotificacaoImportacao(importacao.id)
      .then((response) => {
        const notificacoes = response.resultado || [];
        if (notificacoes.length <= 0) {
          notificacoes.push({
            mensagem:
              'Existem pendências na classificação fiscal dos produtos, já estamos verificando essa situação!',
            nomeArquivo: null,
          });
        }

        Modal.info({
          title: 'Notificações',
          width: '800px',
          onCancel: async () => {
            await importacaoServico.exportarRelatorioNotificacao(importacao.id, Sessao('clienteDocumento'));
          },
          okCancel: notificacoes.length > 0 ? true : false,
          cancelText: notificacoes.length > 1 ? "Exportar notificações" : "Exportar notificação",
          content: (
            <>
              <List
                height="500px"
                style={{ marginLeft: '-38px' }}
                dataSource={notificacoes}
                pagination={{ defaultPageSize: 100 }}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta title={item.mensagem} description={item.nomeArquivo} />
                  </List.Item>
                )}
              />
              
            </>
          ),
        });
      })
      .finally(() => setFetching(false));
  };

  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return <Tag color="#f8f9fa">Iniciado</Tag>;
      case 3:
        return <Tag color="#17a2b8">Em agendamento</Tag>;
      case 4:
        return <Tag color="#007bff">Processando</Tag>;
      case 5:
        return <Tag color="#28a745">Finalizado</Tag>;
      default:
        return <Tag color="#f8f9fa">-</Tag>;
    }
  };

  return cnpj ? (
    <>
      <List
        height="200px"
        header="Histórico de Importação"
        emptyText="Nenhum arquivo importado até o momento"
        loading={loading}
        dataSource={listaImportacao}
        renderItem={(item) => (
          <List.Item>
            <div style={{ width: 150, textAlign: 'center' }}>{renderStatus(item.status)}</div>

            <List.Item.Meta
              title={item.nome || '-'}
              description={moment(item.dataEnvio).format('L LT')}
            />

            {item.existeNotificacao && (
              <Tooltip title="Ver notificação">
                <Button
                  shape="circle"
                  loading={isFetching}
                  danger
                  icon={<BellFilled />}
                  onClick={() => doBuscarNotificacao(item)}
                />
              </Tooltip>
            )}
          </List.Item>
        )}
      />
    </>
  ) : null;
}

HistoricoImportacao.defaultProps = {};

HistoricoImportacao.propTypes = {};

export default memo(HistoricoImportacao);

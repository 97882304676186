import React, { createContext, useContext, useEffect, useState } from 'react';

import axios from '~/Constants/axiosConfig';
import * as auth from '~/Helpers/auth';
import { Sessao } from '~/Helpers/token';

const defaultValue = {
  isAuthenticated: false,
  loading: true,
  usuario: null,
};

const AuthContext = createContext(defaultValue);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState({
    token: Sessao('token'),
    email: Sessao('email'),
    primeiroAcesso: Sessao('primeiroAcesso'),
  });

  const login = (data) => {
    setLoading(true);
    return auth
      .login(data)
      .then((response) => {
        setUsuario({
          token: Sessao('token'),
          email: Sessao('email'),
          primeiroAcesso: Sessao('primeiroAcesso'),
        });
        return response;
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    setLoading(true);
    setUsuario(null);
    return auth.logout().finally(() => setLoading(false));
  };

  useEffect(() => {
    const token = Sessao('token');
    if (token) {
      setUsuario({
        token,
        email: Sessao('email'),
        primeiroAcesso: Sessao('primeiroAcesso'),
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (usuario) {
      const axiosMiddleware = axios.interceptors.request.use((config) => {
        const axiosConfig = config;
        if (usuario.token) {
          axiosConfig.headers.Authorization = `Bearer ${usuario.token}`;
        }

        return axiosConfig;
      });

      return () => axios.interceptors.request.eject(axiosMiddleware);
    }
    return undefined;
  }, [usuario]);

  useEffect(() => {
    const axiosMiddleware = axios.interceptors.response.use(
      (config) => config,
      (error) => {
        if (error && error.response && error.response.status === 401) {
          logout();
        }

        return Promise.reject(error);
      }
    );
    return () => axios.interceptors.response.eject(axiosMiddleware);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        usuario,
        isAuthenticated: usuario ? !!usuario.token : null,
        login,
        logout,
        alterarSenha: auth.alterarSenha,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

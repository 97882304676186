import React from 'react';

import notFoundImage from '~/Assets/Images/404.png';

import S from './styles';

function NotFound() {
  return (
    <S.Container>
      <S.Titulo>Página não encontrada!</S.Titulo>
      <h3>Contate seu Gestor e verifique o endereço correto para acessar o sistema.</h3>
      <img src={notFoundImage} alt="404" />
    </S.Container>
  );
}

export default NotFound;

import React from 'react';

import FileSearchIcon from '@ant-design/icons/FileSearchOutlined';
import { Layout, Space, Card, Badge as AntdBadge } from 'antd';
import styled from 'styled-components';

const Brand = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.logo || '#222b57'};
`;

Brand.Icon = styled(FileSearchIcon)`
  font-size: 38px;
`;

Brand.Texto = styled.h1`
  font: 400 2.8rem 'Baloo Tamma 2', cursive;
  line-height: 0.8;
  letter-spacing: -2px;
  margin: 0.5rem 1.25rem 0 0.5rem;
  color: ${({ theme }) => theme.colors.logo || '#222b57'};
`;

const Header = styled(Layout.Header)`
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  background-color: ${({ theme }) => theme.colors.primario || '#fff'};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  .fieldcustom {
    width: 100%;
    max-width: 400px;
    border-color: ${({ theme }) => theme.colors.logo || '#222b57'};
  }
`;

Header.Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const Navbar = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1rem;
`;

const Notificacao = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-width: 400px;
`;

Notificacao.Header = styled.div`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

Notificacao.Title = styled.span``;

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
`;

User.Content = styled(Space)`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

User.Email = styled.span``;

const StyledBadge = styled(AntdBadge)`
  cursor: pointer;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  > sup.ant-badge-count[data-show='true'] {
    animation: blinker 1s linear infinite;
  }
`;

const BlinkBadge = ({ forwardRef, ...props }) => <StyledBadge ref={forwardRef} {...props} />;

const Meta = styled(Card.Meta)`
  .ant-card-meta-description > span {
    display: block;

    &:last-child {
      text-align: right;
      font-size: 12px;
      color: #1890ff;
    }
  }
`;

export default { Brand, Header, Navbar, Notificacao, User, BlinkBadge, Meta };

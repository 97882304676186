import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Select } from 'antd';

import { FieldCustom } from '~/Components';
import { useImportacao } from '~/Context/importacao';
import { mascaraDocumento } from '~/Helpers/functions';
import { Sessao } from '~/Helpers/token';
import AppContext from '~/Views/App/AppContext';

import S from './styles';

const { Option } = Select;

function ResultadoFiltro() {
  const { loading } = useContext(AppContext);
  const { cnpj, setCnpj } = useImportacao();

  const [empresas] = useState(Sessao('empresas'));

  return (
    <S.Filtro>
      <Row gutter={32} justify="center">
        {loading ? (
          <Col span={6}>
            <Skeleton width="100%" height={48} />
          </Col>
        ) : (
          <>
            <Col span={24} sm={14} xl={10} xxl={7}>
              <FieldCustom className="fieldcustom" icon={faBuilding}>
                <Select
                  size="large"
                  placeholder="Selecionar empresa"
                  bordered={false}
                  defaultValue={cnpj}
                  onChange={setCnpj}
                >
                  <Option value={null}>Todas as empresas</Option>
                  {empresas.map((item) => (
                    <Option key={item.cnpj} value={item.cnpj} label={mascaraDocumento(item.cnpj)}>
                      <span>{item.razaoSocial}</span>
                      <br />
                      <small>{mascaraDocumento(item.cnpj)}</small>
                    </Option>
                  ))}
                </Select>
              </FieldCustom>
            </Col>
          </>
        )}
      </Row>
    </S.Filtro>
  );
}

ResultadoFiltro.defaultProps = {};

ResultadoFiltro.propTypes = {};

export default ResultadoFiltro;

import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

export function hubConnection(route, getToken) {
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SIGNALR_URL}/${route}`, {
      transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
      'content-type': 'application/json',
      accessTokenFactory: getToken,
    })
    .withAutomaticReconnect()
    .build();

  connection.start().catch((err) => console.log(err.toString()));

  return connection;
}

import React, { memo } from 'react';

import { Empty, Pagination, Space } from 'antd';
import PropTypes from 'prop-types';

import S from './styles';

function Table({ emptyText, setPagination, totalItens, rowKey, ...propsTable }) {
  return (
    <Space direction="vertical" size="middle">
      <S.Table locale={{emptyText: (<Empty description={emptyText}/>)}} pagination={false} size="small" rowKey={rowKey} {...propsTable} />

      <Pagination
        hideOnSinglePage
        showSizeChanger
        size="small"
        total={totalItens}
        showTotal={(total, range) => {
          return `${range[0]}-${range[1]} de ${total} ite${total > 1 ? 'ns' : 'm'}`;
        }}
        defaultPageSize={20}
        onChange={(page, pageSize) => setPagination((prev) => ({ ...prev, page, pageSize }))}
      />
    </Space>
  );
}

Table.defaultProps = {
  emptyText: 'Nenhuma informação para mostrar',
  rowKey: 'id',
  setPagination: () => null,
  totalItens: 0,
};

Table.propTypes = {
  emptyText: PropTypes.string,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  setPagination: PropTypes.func,
  totalItens: PropTypes.number,
};

export default memo(Table);

import React, { useEffect } from 'react';

import NProgress from 'nprogress';
import PropTypes from 'prop-types';

import S from './styles';

const Loading = ({ active, fullscreen, text }) => {
  useEffect(() => {
    if (active) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [active]);

  return (
    <S.Loading active={active} fullscreen={fullscreen}>
      <S.Icon src="https://images.e-auditoria.com.br/global/gif/loading.gif" alt="" />
      <S.Text>{text}</S.Text>
    </S.Loading>
  );
};

Loading.defaultProps = {
  active: false,
  fullscreen: false,
  text: '',
};

Loading.propTypes = {
  active: PropTypes.bool,
  fullscreen: PropTypes.bool,
  text: PropTypes.string,
};

export default Loading;

import React from 'react';

import { Col, Row } from 'antd';

import { ImportacaoProvider } from '~/Context/importacao';
import { ResultadoProvider } from '~/Context/resultado';

import Importar from './Importar';
import Resultado from './Resultado';

function Home() {
  return (
    <ImportacaoProvider>
      <ResultadoProvider>
        <Importar />

        <Row justify="center">
          <Col span={23}>
            <Resultado />
          </Col>
        </Row>
      </ResultadoProvider>
    </ImportacaoProvider>
  );
}

export default Home;

import styled from 'styled-components';

const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.25rem 1rem;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.secundario || '#222b57'};
  border-radius: 0.25rem;

  .ant-select {
    width: 100%;
  }
`;

export default { Field };

import { List as ListAntd } from 'antd';
import styled from 'styled-components';

const List = styled(ListAntd)`
  .ant-list-items {
    max-height: ${({ height }) => height};
    padding-right: 1rem;
    overflow: hidden auto;
  }
  .ant-list-header {
    background-color: ${({ theme }) => (theme.colors && theme.colors.secundario) || '#222b57'};
    color: ${({ theme }) => (theme.colors && theme.colors.texto) || '#fff'};
    padding: 8px 8px;

    &:hover {
      opacity: 0.95;
    }
  }
`;

List.Item = styled(ListAntd.Item)``;

List.Item.Meta = styled(ListAntd.Item.Meta)`
  .ant-list-item-meta-description {
    font-size: 0.75rem;
    line-height: 14px;
  }
`;

export default { List };

import axios from '~/Constants/axiosConfig';

const getResultados = async (filtro) => {
  return axios
    .get('Resultado', { params: { ...filtro } })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
};

const downloadDetalhamento = async (cnpj, mes, ano) => {
  const result = axios
    .get('Resultado/Relatorio', { params: { cnpj, mes, ano },  responseType: "blob" })
    .then((response) => {
      if (response.status === 200) {
        const fileName = `relatorio-${cnpj}-${mes}-${ano}.zip`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    })
    .catch(() => {
      return false;
    });

  return result;
};

export default { getResultados, downloadDetalhamento };

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';

import { getStorage, setStorage } from '~/Helpers/storage';
import { importacaoServico } from '~/Servicos';
import { hubConnection } from '~/Servicos/signalr';

import { useAuth } from './auth';

const defaultValue = {
  loading: false,
  cnpj: null,
  buscarResultados: 0,
  listaImportacao: [],
  paginacao: {},
};

const ImportacaoContext = createContext(defaultValue);

export const ImportacaoProvider = ({ children }) => {
  const [cnpj, setCnpj] = useState(undefined);
  const [signalR, setSignalR] = useState(null);
  const [mensagem, setMensagem] = useState(null);
  const [paginacao, setPaginacao] = useState({});
  const [listaImportacao, setListaImportacao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buscarResultados, setBuscarResultados] = useState(0);

  const { usuario } = useAuth();

  useEffect(() => {
    if (mensagem && mensagem.cnpj === cnpj) {
      const novaListaImportacao = [...listaImportacao];
      const index = novaListaImportacao.findIndex(
        (imp) =>
          imp.id === mensagem.id && imp.modulo === mensagem.modulo && imp.cnpj === mensagem.cnpj
      );

      // Atualiza a lista de importações
      if (index >= 0) {
        novaListaImportacao[index] = mensagem;
      } else {
        novaListaImportacao.unshift(mensagem);

        if (novaListaImportacao.length > paginacao.pageSize) {
          novaListaImportacao.splice(-1, 1);
        }
      }

      // Força a busca dos resultados
      if (mensagem.status === 5) {
        setBuscarResultados(buscarResultados + 1);
      }

      // Atualiza a lista de importações
      setListaImportacao(novaListaImportacao);
    }
  }, [mensagem]); // eslint-disable-line

  const initialRender = useCallback(() => {
    if (usuario?.token) {
      if (!signalR) {
        const connection = hubConnection('emultiplicador', () => usuario.token);
        setSignalR(connection);
      } else {
        signalR.on('Mensagens_Importacao', setMensagem);
      }
    }
  }, [signalR, usuario]);

  useEffect(() => {
    initialRender();
    return () => {
      if (signalR) signalR.stop();
    };
  }, [initialRender, signalR]);

  useLayoutEffect(() => {
    setCnpj(getStorage('cnpjSelecionado', 'session') || null);
  }, []);

  const buscarImportacao = (cnpj) => {
    setLoading(true);
    importacaoServico
      .getImportacao({ ...paginacao, cnpj })
      .then((data) => {
        setPaginacao(data.paginacao);
        setListaImportacao(data.resultado.sort((a, b) => a.dataEnvio - b.dataEnvio));
      })
      .catch((error) => setListaImportacao([]))
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    setStorage('cnpjSelecionado', cnpj, 'session');
    if (cnpj) {
      buscarImportacao(cnpj);
    } else {
      setListaImportacao([]);
    }
  }, [cnpj]); // eslint-disable-line

  return (
    <ImportacaoContext.Provider
      value={{
        loading,
        listaImportacao,
        paginacao,
        cnpj,
        setCnpj,
        buscarResultados,
      }}
    >
      {children}
    </ImportacaoContext.Provider>
  );
};

export function useImportacao() {
  const context = useContext(ImportacaoContext);
  return context;
}

import React, { createContext, useContext, useEffect, useState } from 'react';

import moment from 'moment';

import notificacaoServico from '~/Servicos/notificacao';

import { useAuth } from './auth';

const defaultValue = {
  loading: false,
  notificacoes: [],
  visualizarNotificacao: () => null,
};

const NotificacaoContext = createContext(defaultValue);

export const NotificacaoProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const { usuario } = useAuth();

  useEffect(() => {
    let isCancelled = false;

    if (usuario) {
      // Realiza a primeira busca de Notificações
      setLoading(true);
      notificacaoServico
        .buscarNotificacao()
        .then((lista) => {
          if (!isCancelled) {
            setNotificacoes(
              lista.map((n) => ({
                ...n,
                dataCriacao: moment(n.dataCriacao).format('DD/MM/YYYY HH:mm'),
              }))
            );
          }
        })
        .finally(() => {
          if (!isCancelled) setLoading(false);
        });

      // Agenda a busca a cada tantos milisegundos
      const interval = setInterval(() => {
        setLoading(true);
        notificacaoServico
          .buscarNotificacao()
          .then((lista) => {
            if (!isCancelled) {
              setNotificacoes(
                lista.map((n) => ({
                  ...n,
                  dataCriacao: moment(n.dataCriacao).format('DD/MM/YYYY HH:mm'),
                }))
              );
            }
          })
          .finally(() => {
            if (!isCancelled) setLoading(false);
          });
      }, 100000);

      // Cancela o agendamento da busca de notificação
      return () => {
        clearInterval(interval);
        isCancelled = true;
      };
    }

    return undefined;
  }, [usuario]);

  const visualizarNotificacao = (id) => {
    return notificacaoServico.visualizarNotificacao(id).then((resultado) => {
      if (resultado) {
        const novaLista = [...notificacoes];
        const index = novaLista.findIndex((item) => item.id === id);
        if (index >= 0) {
          novaLista.splice(index, 1);
          setNotificacoes(novaLista);
        }
      }
    });
  };

  return (
    <NotificacaoContext.Provider
      value={{
        loading,
        notificacoes,
        visualizarNotificacao,
      }}
    >
      {children}
    </NotificacaoContext.Provider>
  );
};

export function useNotificacao() {
  const context = useContext(NotificacaoContext);
  return context;
}

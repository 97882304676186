import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { LockOutlined } from '@ant-design/icons';
import { Form, Input, message } from 'antd';
import sha1 from 'crypto-js/sha1';

import AppContext from '~/Views/App/AppContext';

import S from './styles';
import { useAuth } from '~/Context/auth';

function AlterarSenha() {
  const history = useHistory();
  const [form] = Form.useForm();

  const {
    config: { logo },
  } = useContext(AppContext);

  const { usuario, alterarSenha } = useAuth();

  const onFinish = (values) => {
    const data = {
      email: usuario.email,
      senhaAtual: sha1(values.senhaAtual.trim()).toString(),
      senhaNova: sha1(values.senhaNova.trim()).toString(),
    };

    alterarSenha(data).then((response) => {
      if (Object.entries(response.errors).length) {
        Object.entries(response.errors).map((erro) =>
          message.error({ duration: 10, content: erro[1] })
        );
      } else {
        history.push('home');
      }
    });
  };

  const validarSenha = (rule, value, dependencie) => {
    if (!value) {
      return Promise.reject('Por favor, confirme a Nova Senha');
    }

    if (value.length < 6) {
      return Promise.reject('A senha deve conter no mínimo 6 caracteres');
    }

    if (dependencie) {
      if (form.getFieldValue(dependencie) === value) {
        return Promise.resolve();
      }

      return Promise.reject('As duas senhas digitadas não coincidem');
    }

    return Promise.resolve();
  };

  return (
    <S.Container>
      <S.Box>
        <S.Brand src={logo} />
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="senhaAtual"
            rules={[{ required: true, message: 'Por favor, informe a Senha Atual' }]}
            hasFeedback
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Senha Atual" />
          </Form.Item>

          <Form.Item
            name="senhaNova"
            rules={[{ validator: (rule, value) => validarSenha(rule, value, null) }]}
            hasFeedback
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Senha Nova" />
          </Form.Item>

          <Form.Item
            name="senhaNovaConfirmar"
            dependencies={['senhaNova']}
            rules={[{ validator: (rule, value) => validarSenha(rule, value, 'senhaNova') }]}
            hasFeedback
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Confirmar Senha Nova"
            />
          </Form.Item>

          <Form.Item>
            <S.Button type="primary" htmlType="submit" size="large">
              Confirmar
            </S.Button>
          </Form.Item>
        </Form>
      </S.Box>
    </S.Container>
  );
}

export default AlterarSenha;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useAuth } from '~/Context/auth';

import AppContext from '~/Views/App/AppContext';
import Layout from '~/Views/Layout';

import routes from './routesUrl';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to={`/${rest.subdominio}`} />;
        }

        return <Component {...props} {...rest} />;
      }}
    />
  );
};

function Routes() {
  const { config } = useContext(AppContext);

  const lidarPath = (path) => {
    if (typeof path === 'string') {
      return `/${config.subdominio}${path}`;
    }
    return path.map((item) => `/${config.subdominio}${item}`);
  };

  return (
    <Router>
      <Switch>
        {routes.map((route) => {
          const newPath = lidarPath(route.path);
          const newRoute = { ...route, path: newPath, subdominio: config.subdominio };

          if (route.layout) {
            return (
              <Layout key={route.key}>
                {route.private ? <PrivateRoute {...newRoute} /> : <Route {...newRoute} />}
              </Layout>
            );
          }
          return route.private ? <PrivateRoute {...newRoute} /> : <Route {...newRoute} />;
        })}
      </Switch>
    </Router>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default Routes;

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Remover não-numéricos
export const stripNonNumeric = (value) => {
  return value.replace(/[\D]+/g, '');
};

// Mascara para documento
export const mascaraDocumento = (value) => {
  const newValue = stripNonNumeric(value);
  if (newValue) {
    if (newValue.length === 14) {
      return newValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    if (newValue.length === 11) {
      return newValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  }
  return value;
};

export const stringToFloat = (valor) => {
  if (typeof valor === 'string') {
    return parseFloat(valor.replace(',', '.'));
  }
  return valor;
};

// Converter número para real
export function formatarReal(valor, prefixo) {
  if (valor !== null && valor !== undefined && valor !== '') {
    if (prefixo) {
      const novoValor = stringToFloat(valor);

      return JSON.parse(novoValor).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    return JSON.parse(valor).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    });
  }
  return '-';
}

// Modifica uma string para ter no máximo `tamanho` caracteres adicionando `...` caso tenha mais
export const ellipsisString = (texto, tamanho) => {
  if (texto.length <= tamanho + 3) {
    return texto;
  }

  return `${texto.substring(0, tamanho)}...`;
};

import axios from '~/Constants/axiosConfig';

export const esqueciMinhaSenha = async (data) => {
  return axios
    .post('/autenticacao/esqueci-minha-senha', data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};

export const novaSenha = async (data) => {
  return axios
    .post('/autenticacao/nova-senha', data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};

import styled from 'styled-components';

const Box = styled.div`
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.boxShadow};

  .ant-menu-vertical,
  .ant-menu-inline {
    border-right: 0;
  }

  &:before {
    content: '${({ title }) => title}';
    font-weight: 600;
    font-size: 1rem;
  }
`;

const Title = styled.h5`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export default { Box, Title };

import React from 'react';

import PropTypes from 'prop-types';

import S from './styles';

function Box({ children, title }) {
  return (
    <S.Box>
      {!!title && <S.Title>{title}</S.Title>}
      {children}
    </S.Box>
  );
}

Box.defaultProps = {
  title: null,
  children: null,
};

Box.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Box;

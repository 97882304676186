export default {
  colors: {
    primaria: '#1e7ae1',
    secundaria: '#1470d6',

    light: '#fff',
    dark: '#717171',

    primaryText: '#313131',
    secondaryText: '#808080',
  },

  boxShadow:
    '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',

  breakpoints: {
    xl: '1599.98px',
    lg: '1199.98px',
    md: '991.98px',
    sm: '767.98px',
    xs: '575.98px',
  },
};

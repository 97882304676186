import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Box = styled.div`
  width: 350px;
  text-align: center;

  .ant-input-prefix {
    color: rgba(0, 0, 0, 0.4);
  }

  .ant-btn {
    width: 100%;
  }
`;

const Brand = styled.img`
  width: 90%;
  margin-bottom: 1.5rem;
`;

const LoginButton = styled(AntdButton)`
  border-color: ${({ theme }) => theme.colors.secundario || '#222b57'};
  background-color: ${({ theme }) => theme.colors.secundario || '#222b57'};
  color: ${({ theme }) => theme.colors.texto || '#fff'};

  &:hover, &:focus, &:active {
    border-color: ${({ theme }) => theme.colors.secundario || '#222b57'};
    background-color: ${({ theme }) => theme.colors.texto || '#fff'};
    color: ${({ theme }) => theme.colors.secundario || '#222b57'};
  }
`;

const EsqueciMinhaSenhaButton = styled(AntdButton)`
  color: '#ababab';
  border: none;
`;

export default { Box, Brand, Container, LoginButton, EsqueciMinhaSenhaButton };

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { resultadoServico } from '~/Servicos';
import { useImportacao } from './importacao';

const { getResultados, downloadDetalhamento } = resultadoServico;

const defaultValue = {
  isLoading: false,
  isDownloading: false,
  resultado: [],
  downloadRelatorioDetalhamento: (mes, ano) => Promise.resolve(),
};

const ResultadoContext = createContext(defaultValue);

export const ResultadoProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [resultado, setResultado] = useState([]);

  const { cnpj, buscarResultados } = useImportacao();

  const downloadRelatorioDetalhamento = (cnpj, mes, ano) => {
    setDownloading(true);
    return downloadDetalhamento(cnpj, mes, ano)
      .finally(() => setDownloading(false));
  }

  const doGetResultados = useCallback(() => {
    if (cnpj !== undefined && !isLoading) {
      setLoading(true);
      return getResultados({ cnpj })
        .then((response) => {
          if (response) {
            setResultado(response.resultado);
          }
        })
        .finally(() => setLoading(false));
    }

    return Promise.resolve();
  }, [cnpj, buscarResultados]); // eslint-disable-line

  useEffect(() => {
    doGetResultados();
  }, [doGetResultados]);

  return (
    <ResultadoContext.Provider
      value={{
        isLoading,
        isDownloading,
        resultado,
        downloadRelatorioDetalhamento,
      }}
    >
      {children}
    </ResultadoContext.Provider>
  );
};

export function useResultado() {
  const context = useContext(ResultadoContext);
  return context;
}

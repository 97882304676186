import axios from '~/Constants/axiosConfig';

const getConfiguracao = async () => {
  return axios
    .get('check')
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};

export default { getConfiguracao };

import React, { useCallback, useEffect, useState } from 'react';

import { ConfigProvider } from 'antd';
import antdPtBr from 'antd/es/locale/pt_BR';
import moment from 'moment';
import NProgress from 'nprogress';
import { ThemeProvider } from 'styled-components';
import 'moment/locale/pt-br';

import 'nprogress/nprogress.css';
import '~/Assets/Styles/antd/antd.less';

import CustomStyle from '~/Assets/Styles/custom';
import GlobalStyle from '~/Assets/Styles/global';
import temaPadrao from '~/Assets/Styles/theme';
import Routes from '~/Constants/Routes';
import { AuthProvider } from '~/Context/auth';
import { configServico } from '~/Servicos';

import NotFound from '../NotFound';
import { AppProvider } from './AppContext';

const { getConfiguracao } = configServico;

moment().locale('pt-br');

function App() {
  NProgress.configure({ parent: '.ant-layout-content' });

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  const doGetConfiguracao = async () => {
    await getConfiguracao()
      .then((response) => {
        if (response) {
          const novoConfig = {
            ...response,
            tema: response.tema ? JSON.parse(response.tema) : {},
            redesSociais: response.redesSociais ? JSON.parse(response.redesSociais) : [],
            rodape: JSON.parse(response.rodape)
          };
          setConfig(novoConfig);
        } else {
          setConfig(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });

     
  };

  const initialRender = useCallback(async () => {
    await doGetConfiguracao();
    // await getRodape();
  }, []); // eslint-disable-line

  useEffect(() => {
    initialRender();
  }, [initialRender]);

  useEffect(() => {
    if (config) {
      // Personalizar título da página
      document.title = `${
        config.dominio.logo
      } :: ${config.subdominio.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())}`;

      // Personalizar cor abas
      const metaThemeColor = document.querySelector('meta[name=theme-color]');
      metaThemeColor.setAttribute('content', config.tema.logo);
    }
  }, [config]);

  const valueContext = { config, loading };

  if (config) {
    return (
      <ConfigProvider locale={antdPtBr}>
        <ThemeProvider theme={{ ...temaPadrao, colors: { ...temaPadrao.colors, ...config.tema } }}>
          <AuthProvider>
            <AppProvider value={valueContext}>
              <GlobalStyle />
              <CustomStyle />

              <Routes />
            </AppProvider>
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    );
  }
  if (!loading) {
    return <NotFound />;
  }
  return null;
}

export default App;

import axios from '~/Constants/axiosConfig';

const buscarNotificacao = () => {
  return axios
    .get(`Notificacao`)
    .then((response) => {
      if (response.status === 200) {
        return response.data.resultados;
      }
      return [];
    })
    .catch(() => {
      console.log('Erro ao consultar api sobre as notificações');
      return [];
    });
};

const visualizarNotificacao = (id) => {
  return axios
    .put(`Notificacao/${id}`)
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
      return false;
    })
    .catch(() => {
      console.log('Erro ao consultar api sobre as notificações');
      return false;
    });
};

export default { buscarNotificacao, visualizarNotificacao };

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload as UploadAntd } from 'antd';
import styled from 'styled-components';

const Dragger = styled(UploadAntd.Dragger)`
  &.ant-upload {
    &.ant-upload-drag {
      height: auto;
      background: unset;
      border: 0;
      cursor: inherit;
      
      .ant-upload-drag-container {
        vertical-align: top;
      }

      & .ant-upload-btn {
        padding: 0;
      }

      .ant-upload-text,
      .ant-upload-hint {
        color: ${({ theme }) => theme.colors.texto || '#fff'};
      }
    }

    .ant-upload {
      width: 100%;
      padding: 1rem 0 3rem;
    }

    &.ant-upload-disabled {
      * {
        cursor: auto;
      }
    }
  }
`;

const Upload = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.secundario || '#222b57'};
  cursor: pointer;
  width: 100%;
  padding: 1rem 0 3rem;
  text-align: center;

  .ant-upload-list:not(:empty) {
    margin: 1rem auto;
    background: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    max-width: 500px;

    .ant-upload-list-text-container:last-child {
      padding-bottom: 8px;
    }
  }
`;

Upload.Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.texto || '#fff'};
`;

export default { Dragger, Upload };

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { Form, Input, message } from 'antd';
import sha1 from 'crypto-js/sha1';

import AppContext from '~/Views/App/AppContext';
import { useAuth } from '~/Context/auth';

import S from './styles';

function Login() {
  const history = useHistory();
  const { loading, login } = useAuth();

  const {
    config: { logo, subdominio },
  } = useContext(AppContext);

  const onFinish = (values) => {
    const data = {
      email: values.email.trim(),
      senha: sha1(values.senha.trim()).toString(),
    };

    login(data).then((response) => {
      if (response.sucesso) {
        if (response.conteudo.primeiroAcesso) {
          history.push(`/${subdominio}/alterar-senha`);
        } else {
          history.push(`/${subdominio}/home`);
        }
      } else {
        message.error(response.mensagem);
      }
    });
  };

  function redirectEsqueciMinhaSenha() {
    history.push(`/${subdominio}/esqueci-minha-senha`);
  }

  return (
    <S.Container>
      <S.Box>
        <S.Brand
          src={logo || 'https://images.e-auditoria.com.br/global/logos/svg/eauditoria.svg'}
        />
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Por favor, informe o E-mail' }]}
            hasFeedback
          >
            <Input size="large" prefix={<UserOutlined />} placeholder="E-mail" />
          </Form.Item>

          <Form.Item
            name="senha"
            rules={[{ required: true, message: 'Por favor, informe a Senha' }]}
            hasFeedback
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Senha" />
          </Form.Item>

          <Form.Item>
            <S.LoginButton
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              icon={<LoginOutlined />}
            >
              Entrar
            </S.LoginButton>
          </Form.Item>

          <Form.Item>
            <S.EsqueciMinhaSenhaButton onClick={redirectEsqueciMinhaSenha}>
              Esqueci minha senha
            </S.EsqueciMinhaSenhaButton>
          </Form.Item>
        </Form>
      </S.Box>
    </S.Container>
  );
}

export default Login;

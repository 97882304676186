import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* Layout */
  .ant-layout {
    background-color: #fff;
  }

  /* Content */
  .ant-layout-content {
    height: calc(100vh - 113px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* Space */
  .ant-space-vertical {
    width: 100%;
  }

  /* NProgress */
  #nprogress .bar {
    background: #43aa58;
    height: 4px;
  }

  /* ColorPicker */
  .chrome-picker {
    background-color: transparent !important;
    box-shadow: none !important;
  }
`;

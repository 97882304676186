import axios from '~/Constants/axiosConfig';

import { clearStorage } from './storage';
import { setSessaoToken, limparSessao } from './token';

const isDevelopment = () => process.env.NODE_ENV === 'development';

const login = async (data) => {
  return axios
    .post('Autenticacao', data)
    .then((response) => {
      setSessaoToken(response.data.conteudo);
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const logout = async () => {
  return Promise.all([clearStorage(), limparSessao()])
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const alterarSenha = async (data) => {
  return axios
    .post('Autenticacao/modificar/senha', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export { isDevelopment, login, logout, alterarSenha };

import React from 'react';

import { Layout } from 'antd';
import PropTypes from 'prop-types';

import Footer from './Footer';
import Header from './Header';

import { NotificacaoProvider } from '~/Context/notificacao';

const { Content } = Layout;

function Main({ children }) {
  return (
    <NotificacaoProvider>
      <Layout>
        <Header />

        <Content>{children}</Content>

        <Footer />
      </Layout>
    </NotificacaoProvider>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;

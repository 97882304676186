import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { CheckCircleTwoTone, CloseCircleTwoTone, DownloadOutlined } from '@ant-design/icons';
import { Button, Tag, Tooltip, message } from 'antd';
import moment from 'moment';
import shortid from 'shortid';

import { Table } from '~/Components';
import { useResultado } from '~/Context/resultado';

import { mascaraDocumento } from '~/Helpers/functions';

function ResultadoLista() {
  const { isLoading, isDownloading, resultado, downloadRelatorioDetalhamento } = useResultado();

  const doDownloadResultado = (cnpj, competencia) => {
    const data = new Date(competencia);
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();

    return downloadRelatorioDetalhamento(cnpj, mes, ano).catch(() => {
      message.error('Tivemos um problema ao consultar o relatório. Tente novamente mais tarde');
    });
  };

  const renderStatus = (value) => {
    if (value) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />;
    }
    return <CloseCircleTwoTone twoToneColor="#f5222d" />;
  };

  const renderResultado = (value) => {
    if (value) {
      return (
        <Tooltip title="Baixe a planilha com a segregação das receitas">
          <Tag
            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            color="green"
            style={{ marginRight: 0 }}
          >
            Resultado gerado
          </Tag>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Existem pendências na classificação fiscal dos produtos, já estamos verificando essa situação!">
        <Tag
          icon={<CloseCircleTwoTone twoToneColor="#f5222d" />}
          color="red"
          style={{ marginRight: 0 }}
        >
          Pendências detectadas
        </Tag>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Razão Social',
      dataIndex: 'razaoSocial',
      width: 150,
      sorter: (a, b) => `${a.razaoSocial}`.localeCompare(b.razaoSocial),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      align: 'center',
      width: 50,
      sorter: (a, b) => `${a.Cnpj}`.localeCompare(b.Cnpj),
      render: (value) => mascaraDocumento(value)
    },
    {
      title: 'Competência',
      dataIndex: 'competencia',
      align: 'center',
      width: 50,
      sorter: (a, b) => `${a.competencia}`.localeCompare(b.competencia),
      render: (value) => moment(value).format('MM/YYYY'),
    },
    {
      title: 'Documentos fiscais',
      dataIndex: 'temMovimentacao',
      align: 'center',
      width: 60,
      sorter: (a, b) => a.temMovimentacao - b.temMovimentacao,
      render: renderStatus,
    },
    {
      title: 'Status da Auditoria',
      dataIndex: 'resultadoOk',
      align: 'center',
      width: 70,
      sorter: (a, b) => `${a.resultadoOk}`.localeCompare(b.resultadoOk),
      render: renderResultado,
    },
    {
      align: 'center',
      fixed: 'right',
      width: 20,
      render: (value, record) => (
        <Tooltip
          title="Planilha de segregação de receitas"
          trigger={record.resultadoOk ? 'hover' : 'none'}
        >
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            shape="circle"
            disabled={!record.resultadoOk}
            loading={isDownloading}
            onClick={() => doDownloadResultado(record.cnpj, record.competencia)}
          />
        </Tooltip>
      ),
    },
  ];

  const propsTable = {
    columns,
    emptyText: 'Nenhum resultado para ser visualizado no momento',
    rowKey: () => shortid.generate(),
    dataSource: resultado,
    scroll: { x: 1350 },
  };

  return isLoading ? (
    <>
      <Skeleton height={40} />
      <Skeleton count={10} height={26} />
    </>
  ) : (
    <Table {...propsTable} />
  );
}

ResultadoLista.defaultProps = {};

ResultadoLista.propTypes = {};

export default ResultadoLista;

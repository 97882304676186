import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  flex-direction: column;

  img {
    height: 52vh;
  }
`;

const Titulo = styled.h2`
  margin-bottom: 40px;
`;

export default { Container, Titulo };

import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  crossdomain: true,
  withCredentials: true,
});

axios.interceptors.request.use(async (config) => {
  const axiosConfig = config;
  axiosConfig.headers['x-referer'] = window.document.location.href;
  return axiosConfig;
});

axios.interceptors.response.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;

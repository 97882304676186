import styled, { css } from 'styled-components';

const Icon = styled.img`
  height: 3.5rem;
`;

const Loading = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 1000;

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      position: fixed;
      width: 100vw;
      height: 100vh;
    `}
`;

const Text = styled.span`
  margin: 0.75rem 0;
`;

export default { Icon, Loading, Text };

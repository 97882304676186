import React, { useContext } from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import { MailOutlined } from '@ant-design/icons';
import { faBell, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Card, Dropdown, Empty, Modal, Skeleton } from 'antd';
import shortid from 'shortid';

import { Box } from '~/Components';
import { useAuth } from '~/Context/auth';
import { useNotificacao } from '~/Context/notificacao';
import { ellipsisString } from '~/Helpers/functions';
import AppContext from '~/Views/App/AppContext';

import S from './styles';

function Header() {
  const history = useHistory();
  const { config, loading: isLoadingApp } = useContext(AppContext);
  const { logout, usuario } = useAuth();
  const { loading: isLoadingNotificacao, notificacoes, visualizarNotificacao } = useNotificacao();

  const doLogout = () => {
    logout().then((response) => {
      if (response) {
        history.push(`/${config.subdominio}`);
      }
    });
  };

  const doVisualizarNotificacao = (notificacao) => {
    Modal.info({
      title: notificacao.titulo,
      content: (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: notificacao.mensagem.replaceAll('<b>', '<br/><b>') }}
        />
      ),
      zIndex: 2000,
      onOk: () => visualizarNotificacao(notificacao.id),
    });
  };

  const notificacaoBox = (
    <Box title="Notificações">
      {notificacoes.length > 0 ? (
        notificacoes.map((n) => (
          <Card key={shortid.generate()} style={{ width: 500 }}>
            <Skeleton loading={isLoadingNotificacao} avatar active>
              <S.Meta
                avatar={<Avatar icon={<MailOutlined />} size={64} />}
                style={{ cursor: 'pointer' }}
                title={n.titulo}
                description={
                  <>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: ellipsisString(n.mensagem, 110) }} />
                    <span>{n.dataCriacao}</span>
                  </>
                }
                onClick={() => doVisualizarNotificacao(n)}
              />
            </Skeleton>
          </Card>
        ))
      ) : (
        <Empty description="Nenhuma notificação" />
      )}
    </Box>
  );

  const usuarioBox = (
    <Box>
      <S.User>
        <S.User.Content direction="vertical" align="center">
          <FontAwesomeIcon icon={faUserCircle} size="3x" />
          <S.User.Email>{usuario?.email}</S.User.Email>
        </S.User.Content>

        <Button size="small" onClick={doLogout}>
          Sair da Conta
        </Button>
      </S.User>
    </Box>
  );

  return (
    <>
      {isLoadingApp ? (
        <ReactSkeleton height={60} />
      ) : (
        <S.Header>
          <S.Header.Left>
            <S.Brand>
              <S.Brand.Icon />
              {config.dominio ? (
                <S.Brand.Texto>{config.dominio.logo}</S.Brand.Texto>
              ) : (
                <S.Brand.Texto>Domínio</S.Brand.Texto>
              )}
            </S.Brand>
          </S.Header.Left>

          <S.Navbar>
            <Dropdown overlay={notificacaoBox} trigger={['click']}>
              <S.BlinkBadge count={notificacoes.length} overflowCount={5}>
                <FontAwesomeIcon
                  icon={faBell}
                  size="2x"
                  cursor="pointer"
                  color={config.tema.logo || '#222b57'}
                  style={{ marginLeft: '21px' }}
                />
              </S.BlinkBadge>
            </Dropdown>

            <Dropdown overlay={usuarioBox} trigger={['click']}>
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                cursor="pointer"
                color={config.tema.logo || '#222b57'}
              />
            </Dropdown>
          </S.Navbar>
        </S.Header>
      )}
    </>
  );
}

export default Header;

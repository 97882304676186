import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { UserOutlined, LoginOutlined } from '@ant-design/icons';
import { Form, Input, message, Typography } from 'antd';

import AppContext from '~/Views/App/AppContext';
import { esqueciMinhaSenha } from '~/Servicos/autenticacao';

import S from './styles';

const { Title } = Typography;

function EsqueciMinhaSenha() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const {
    config: { logo, subdominio },
  } = useContext(AppContext);

  const validateMessages = {
    types: {
      email: 'E-mail não é válido',
    },
  };

  const onFinish = (values) => {
    const data = {
      email: values.email.trim(),
    };

    setLoading(true);

    esqueciMinhaSenha(data).then((response) => {
      if (response && response.sucesso) {
        setLoading(false);
        history.push(`/${subdominio}`);
      } else {
        setLoading(false);

        if (response && response.mensagem) {
          message.error(response.mensagem);
        }

        message.error('Erro ao solicitar nova senha');
      }
    });
  };

  return (
    <S.Container>
      <S.Box>
        <S.Brand
          src={logo || 'https://images.e-auditoria.com.br/global/logos/svg/eauditoria.svg'}
        />

        <Title level={5} type="secondary">
          Digite seu e-mail de cadastro abaixo e clique em enviar.
        </Title>

        <Form layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
          <Form.Item
            name="email"
            type="mail"
            rules={[{ required: true, message: 'Por favor, informe o E-mail' }, { type: 'email' }]}
            hasFeedback
          >
            <Input size="large" prefix={<UserOutlined />} placeholder="E-mail" />
          </Form.Item>

          <Form.Item>
            <S.EnviarButton
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              icon={<LoginOutlined />}
            >
              Enviar
            </S.EnviarButton>
          </Form.Item>
        </Form>
      </S.Box>
    </S.Container>
  );
}

export default EsqueciMinhaSenha;
